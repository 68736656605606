import React from "react";
import PropTypes from "prop-types";

import styles from "./chat-button.module.scss";
import ButtonHoverIcon from "./button-hover-icon";

/**
 * This is the component to render a chat button at contact us page.
 *
 * @param {Object} props.props - The object containing props.
 * @param {string} props.className - optional classes for the button.
 * @param {Object} props.btnProps - required object with the data for the button.
 *   - `id`: required string id for the button.
 *   - `text`: required string text for the button.
 *   - `icon`: required path of the icon at the contact button.
 *   - `iconHover`: required path for the hover version of the icon at the contact button.
 *   - `url`: optional string path for the anchor element href.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const ChatButton = ({ className, btnProps }) => {
  return <ButtonHoverIcon className={`${styles.btnChat} ${className ? className : ""}`} {...btnProps} />;
};

export default ChatButton;

ChatButton.propTypes = {
  className: PropTypes.string,
  btnProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconHover: PropTypes.string.isRequired,
    url: PropTypes.string
  })
};
