import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./button-hover-icon.module.scss";
import { useLanguageContext } from "../../contexts/language-context";

/**
 * This is the component to render the contact button with hover effect at contact us page.
 *
 * @param {Object} props.props - The object containing props.
 * @param {string} props.id - required string id for the button.
 * @param {string} props.text - required string text for the button.
 * @param {string} props.icon - required path of the icon at the contact button.
 * @param {string} props.iconHover - required path for the hover version of the icon at the contact button.
 * @param {string} props.url - optional string path for the anchor element href.
 * @returns {React.JSX.Element} Returns the JSX component to render.
 */
const ButtonHoverIcon = ({ icon, iconHover, text, subtext, className, url, id, type, onClick, chatLoading }) => {
  const [hover, setHover] = useState(false);
  const isSpanish = useLanguageContext();

  if (type === "button") {
    return (
      <button
        id={id}
        class={`btn d-flex align-items-center text-left ${
          isSpanish ? styles.btnHoverIconSpanish : styles.btnHoverIcon
        } ${className}`}
        type={type}
        onClick={() => onClick()}
        disabled={chatLoading}
      >
        <div className={`d-flex align-items-center justify-content-center ${styles.iconContainer}`}>
          <img alt="" src={hover ? iconHover : icon} style={{ height: "42px" }} />
        </div>
        <div>
          <h4 className="font-weight-bold m-0">{text}</h4>
          {subtext && <div className="text-left">{subtext}</div>}
        </div>
      </button>
    );
  }

  return (
    <a
      id={id}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      className={`btn d-flex align-items-center text-left ${
        isSpanish ? styles.btnHoverIconSpanish : styles.btnHoverIcon
      } ${className}`}
      href={url}
    >
      <div className={`d-flex align-items-center justify-content-center ${styles.iconContainer}`}>
        <img alt="" src={hover ? iconHover : icon} style={{ height: "42px" }} />
      </div>
      <div>
        <h4 className="font-weight-bold m-0">{text}</h4>
        {subtext && <div className="text-left">{subtext}</div>}
      </div>
    </a>
  );
};

export default ButtonHoverIcon;

ButtonHoverIcon.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconHover: PropTypes.string.isRequired,
  url: PropTypes.string
};
