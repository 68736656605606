import React from "react";
import PropTypes from "prop-types";
import Icon from "../custom-widgets/icon";
import ChatButton from "./chat-button";

const ChatSection = ({ customerServiceButtons }) => {
  return (
    <section className="container pt-2">
      <div className="row">
        <div className="col col-lg-4 mb-4 mb-lg-0">
          {customerServiceButtons.map((btn) => (
            <ChatButton {...btn} />
          ))}
        </div>
        <div className="col col-lg-8">
          <p className="mb-4">
            Para llamadas internacionales, por favor, marque{" "}
            <a id="international-help-tel" className="text-decoration-none" href="tel:1-206-624-7930">
              1-206-624-7930
            </a>
          </p>
          <p className="text-green-60 mb-4">
            <strong>
              El servicio al cliente de Wafd Bank está disponible las 24 horas al día los 7 días de la semana.
            </strong>
          </p>
          <h4>Necesita ayuda con su tarjeta de debido WaFd?</h4>
          <p>
            <a id="c3-help-tel-1" className="text-decoration-none" href="tel:800-324-9375">
              <Icon class="mr-2" lib="fal" name="phone-alt" />
              Llame 800-324-9375
            </a>{" "}
            si perdió su tarjeta de débito
          </p>
          <p className="mb-0">
            <a id="pin-help-tel" className="text-decoration-none" href="tel:866-661-8550">
              <Icon class="mr-2" lib="fal" name="phone-alt" />
              Llame 866-661-8550
            </a>{" "}
            si olvido su PIN
          </p>
        </div>
      </div>
    </section>
  );
};

ChatSection.propTypes = {
  customerServiceButtons: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      btnProps: PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
        subtext: PropTypes.string,
        icon: PropTypes.string,
        iconHover: PropTypes.string
      })
    })
  )
};

export default ChatSection;
